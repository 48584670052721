import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import './Contact.css'; // Add your custom CSS for styling
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import contactPic from './speech.png';
const Contact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useRef();


  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: ""
  });

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitForm = () => {
    emailjs.sendForm('service_rh1jpo5', 'template_judcrbb', form.current, 'PiPxWvpqd08fMVgdX')
      .then((result) => {
        setIsModalOpen(false)
        setFormData({
          user_name: "",
          user_email: "",
          message: ""
        });

        toast.success("Email Sent Successfully", { theme: "light" });
      }, (error) => {
        console.log(error.text);
        toast.error("Something Went Wrong", { theme: "light" });
      });
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="contact-widget-container">
      <button className="toggle-button" onClick={handleModalToggle}>
        <img src={contactPic} alt="Message Icon" />
      </button>

      <Modal show={isModalOpen} onHide={handleModalToggle} className="contactModal" centered>
        <Modal.Header>
          <Modal.Title>Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()} ref={form}>
            <div className="mb-3">
              <label htmlFor="user_name" className="form-label">
                Name:
              </label>
              <input type="text" className="form-control" id="user_name" name="user_name" value={formData.user_name} onChange={handleFormData} />
            </div>

            <div className="mb-3">
              <label htmlFor="user_email" className="form-label">
                Email:
              </label>
              <input type="email" className="form-control" id="user_email" name="user_email" value={formData.user_email} onChange={handleFormData} />
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message:
              </label>
              <textarea className="form-control" id="message" name="message" rows="4" value={formData.message} onChange={handleFormData} />
            </div>

            <button type="button" className="btn btn-dark" onClick={submitForm}>
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Contact;
