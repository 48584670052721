import React, { useState, useEffect } from 'react'
import './Main.css';
import BlockImage from './BlockImage';
import DisplayContainer from './DisplayContainer';
const Main = () => {

  const [count, setCount] = useState(2)

  const conditionalStyle = (id) => {
    if (count === id) {
      return { backgroundColor: 'white' };
    } else {
      return {}; // Return an empty object if styles are not to be applied
    }
  };

  return (
    <div className="wrapper">
    <div className="homeContainer">
      <div style={{marginLeft:"10px"}} className="topNameContainer">
        <h4>
          Darien Chetram
        </h4>
        
        <h6>
          Full Stack Web Developer
        </h6>
      </div>

    
    <div className="mainContainer" onClick={(()=>setCount(1))} style={conditionalStyle(1)}>
       

        {/* <div className="blockImageContainer">
                    <BlockImage/>
</div> */}
            <h4>
              Tech
                
            </h4>

        
    </div>
    <div className="aboutContainer" onClick={(()=>setCount(2))} style={conditionalStyle(2)}>
      <h4>About Me
        
      </h4>
    </div>
    <div className="projectContainer" onClick={(()=>setCount(3))} style={conditionalStyle(3)}>
      <h4>Projects
        
      </h4>

    </div>
 
    </div>
    <div className="displayContainerDiv">
    <DisplayContainer count={count}/>
    </div>
    </div>
  )
}

export default Main