import React from 'react';
import { motion } from 'framer-motion';
import './GradientBackground.css';

const GradientBackground = () => {
  return (
    <motion.div
      className="gradientBackground"
      initial={{ background: 'linear-gradient(45deg, #FFDEE9, #B5FFFC)' }} // Light pink to light blue
      animate={{ background: ['linear-gradient(45deg, #FFDEE9, #B5FFFC)', 'linear-gradient(45deg, #F9F586, #FDB2FF)'] }} // Light yellow to light purple
      transition={{ duration: 8, repeat: Infinity, repeatType: 'reverse' }}
    />
  );
};

export default GradientBackground;
