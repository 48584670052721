import React from 'react'
import './Creds.css';
import TechStack from '../TechStack';
const Creds = () => {
  return (
    <div className="credContainer">
        {/* <h5>
            Welcome To My Portfolio Site
        </h5>
        <br/>
        <p>
          Hey! My name is Darien thanks for checking out my website.
        </p>

        <br/>
        <p>
        I'm a full stack web developer living in Toronto.
        </p> */}

        <TechStack/>
        

    </div>
  )
}

export default Creds