import React from 'react'
import transition from '../transition'
import Description from '../components/Description';

const About = () => {
  return (
    <div>
        <Description/>
    </div>
  )
}

export default transition(About);