import React, { useEffect } from 'react';
import './DisplayContainer.css';
import Creds from './displayComponents/Creds';
import VideoCaro from './VideoCaro';
import Canvas from './displayComponents/game/Canvas';
import 'animate.css';
import Description from './Description';
const DisplayContainer = ({ count }) => {
  useEffect(() => {
    console.log(count);
  }, [count]);

  const getClassName = (componentCount) => {
    return count === componentCount ? "animate__animated animate__fadeIn" : "animate__animated animate__fadeOut";
  };

  return (
    <div>
      <div className={getClassName(1)}>
        {count === 1 && <Creds />}
      </div>
      <div className={getClassName(2)}>
        {count === 2 && <Description />}
      </div>
      <div className={getClassName(3)}>
        {count === 3 && <VideoCaro />}
      </div>
    </div>
  );
}

export default DisplayContainer;
