import React, {useRef} from 'react'
import Navbar from '../components/Header'
import Main from '../components/Main'
import Contact from '../components/Contact';
import transition from '../transition';
import './Home.css';
const Home = () => {

  return (
    <div className="home">
        <Main/>
        
    </div>
  )
}

export default transition(Home);