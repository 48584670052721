import React from 'react';
import './Description.css';

const Description = () => {
  return (
    <div className="description-container">
      <h2 className="description-title"><b>About Me</b></h2>
      <div className="description-content">
        <div className="description-item">
          <p>
          Hey, my name is Darien thanks for checking out my site! 
          </p>
        </div>
        
        <div className="description-item">
          <p>
           I started coding at my accounting job, where I sought to automate some basic tasks. As I learned more and more, my fascination with the power and potential of programming grew.
          </p>
        </div>
        
        <div className="description-item">
          <p>
            I started with Python for basic scripting, then got comfortable with Django when I wanted to learn how to make websites. Later, I learned JavaScript to make dynamic frontends, primarily using React.
          </p>
        </div>
        
        <div className="description-item">
          <p>
            In my spare time, I enjoy working out, playing golf, and a good book.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Description;