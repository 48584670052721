import React from 'react'
import transition from '../transition'
import VideoCaro from '../components/VideoCaro';
import PortfolioVid from '../components/PortfolioVid';
const Portfolio = () => {
  return (
    <div>
        <VideoCaro/>
     </div>
  )
}

export default transition(Portfolio);