import React, { useRef, useEffect, useState } from 'react';

const Canvas = () => {
  const canvasRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [swung, setSwung] = useState(false);
  const [resetGame, setResetGame] = useState(false); // Track game reset
  const [yards, setYards] = useState(0)
  const [peakY, setPeakY] = useState(400); // Assuming 400 is the starting Y position of the ball
  const [endPos, setEndPos] = useState({ x: 0, y: 0 });
  const [ballPos, setBallPos] = useState({ x: 300, y: 400 });
  const [velocity, setVelocity] = useState({ x: 0, y: 0 });
  const [acceleration, setAcceleration] = useState({ x: 0, y: 0.5 }); // Gravity effect
  const [swingProgress, setSwingProgress] = useState(0);
  const [swingAngle, setSwingAngle] = useState(0);
  const dampingFactor = 1; // Damping factor to slow down the swing


  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Create a gradient for the tunnel effect
    const gradient = context.createLinearGradient(0, 0, 0, 390);

    // Add color stops to the gradient
    gradient.addColorStop(0, 'transparent'); // Start transparent
    gradient.addColorStop(0.5, 'white'); // Gradually add green
    gradient.addColorStop(1, 'green'); // Fully green at 390

    // Fill the background with the gradient
    context.fillStyle = gradient;
    context.fillRect(0, 200, canvas.width, 390);

    // Fill the area below y = 390 with green for grass
    context.fillStyle = 'green';
    context.fillRect(0, 390, canvas.width, canvas.height - 390);


    // Draw the ball
    context.beginPath();
    context.arc(ballPos.x, ballPos.y, 7, 0, 2 * Math.PI);
    context.fillStyle = 'white';
    context.fill();

        // Display the yards
    context.font = '20px Arial';
    context.fillStyle = 'black';
    context.fillText(`Yards: ${yards.toFixed(2)}`, 10, 30);

    // Draw the dragging line
    if (isDragging) {
      context.beginPath();
      context.moveTo(startPos.x, startPos.y);
      context.lineTo(endPos.x, endPos.y);
      context.strokeStyle = 'black';
      context.lineWidth = 3; // Set line thickness here
      context.stroke();
    }

    // Draw the stickman
    drawStickman(context, swingAngle);

  }, [isDragging, startPos, endPos, ballPos, swingAngle]);

  const drawStickman = (context, angle) => {
    const baseX = 260;
    const baseY = 390;

    // Set line width for the stickman
    context.lineWidth = 2; // Set line thickness here

    // Head
    context.beginPath();
    context.arc(baseX, baseY - 60, 10, 0, 2 * Math.PI);
    context.stroke();

    // Body
    context.beginPath();
    context.moveTo(baseX, baseY - 50);
    context.lineTo(baseX, baseY - 10);
    context.stroke();

    // Legs
    context.beginPath();
    context.moveTo(baseX, baseY - 10);
    context.lineTo(baseX - 10, baseY + 20);
    context.moveTo(baseX, baseY - 10);
    context.lineTo(baseX + 10, baseY + 20);
    context.stroke();

    // Save context and apply rotation for arms and golf club
    context.save();
    context.translate(baseX, baseY - 40); // Move origin to the shoulder
    context.rotate(angle);

    // Arms
    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(15, 20);
    context.stroke();

    // Shaft
    context.beginPath();
    context.moveTo(15, 17);
    context.lineTo(35, 50);
    context.stroke();

    // Head
    context.beginPath();
    context.moveTo(45, 50);
    context.lineTo(35, 50);
    context.lineWidth = 3; // Increase the line width for the club head
    context.stroke();


    context.restore(); // Restore context to original state
  };

  useEffect(() => {
    const moveBall = () => {
      setVelocity((vel) => ({
        x: vel.x,
        y: vel.y + acceleration.y,
      }));
      

      setBallPos((pos) => {
        let newX = pos.x + velocity.x;
        let newY = pos.y + velocity.y;

        // Check for collision with the left and right borders
        if (newX <= 10 || newX >= canvasRef.current.width - 10) {
          setVelocity((vel) => ({ ...vel, x: -vel.x }));
          newX = newX <= 10 ? 10 : canvasRef.current.width - 10;
        }

        // Check for collision with the top and bottom borders
        if (newY <= 10 || newY >= canvasRef.current.height - 10) {
          setVelocity((vel) => ({ ...vel, y: -vel.y }));
          newY = newY <= 10 ? 10 : canvasRef.current.height - 10;
        }

      // Track peak Y
      if (newY < pos.y) {
        setPeakY(newY);
      }

      // Stop the ball if it falls below a certain height after reaching the peak
      const groundY = 400;
      const stopHeight = groundY - peakY / 8;

      if (newY > groundY) {
        setVelocity({ x: 0, y: 0 });
      } else if (newY > stopHeight && newY > peakY) {
        setVelocity({ x: 0, y: 0 });
      }
        // Stop the ball if it hits the ground
        


        

        return { x: newX, y: newY };
      });

      // Apply friction to slow the ball down
      setVelocity((vel) => ({
        x: vel.x * 0.99,
        y: vel.y * 0.99,
      }));
      // Calculate yards based on the distance traveled by the ball
      setYards((yards) => yards + Math.sqrt(Math.pow(velocity.x, 2) + Math.pow(velocity.y, 2)) * 0.1);

      // Stop the ball when it's slow enough
      if (Math.abs(velocity.x) < 0.1 && Math.abs(velocity.y) < 0.1) {
        setVelocity({ x: 0, y: 0 });
      }
           // Reset ball position if the first swing is complete and game needs reset
           if (swung && (Math.abs(velocity.x) < 0.1 && Math.abs(velocity.y) < 0.1) && resetGame) {
            setBallPos({ x: 300, y: 400 });
            setSwung(false); // Reset swung state
            setResetGame(false); // Reset game reset state
          }

      
    };

    const interval = setInterval(moveBall, 16);
    return () => clearInterval(interval);
  }, [velocity, acceleration]);

  const handleMouseDown = (e) => {
    setYards(0)
    setPeakY(0)
    setBallPos({ x: 300, y: 400 });
 
    setIsDragging(true);
    setStartPos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  
};

  const handleMouseMove = (e) => {
    if (isDragging) {
      const dx = startPos.x - e.nativeEvent.offsetX;
      const dy = startPos.y - e.nativeEvent.offsetY;
      const angle = Math.atan2(dy, dx);

      setSwingAngle(-angle); // Update swing angle based on mouse movement
      setEndPos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    }
  };
  

  const handleMouseUp = (e) => {
    setIsDragging(false);
    // Update swing progress
    
    const dx = startPos.x - endPos.x;
    const dy = startPos.y - endPos.y;
    const angle = Math.atan2(dy, dx);
    setSwingAngle(angle); // Update swing angle based on mouse movement
    setEndPos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    setVelocity({ x: dx / 10, y: dy / 10 });

  };
  const handleGameClick = () => {
    if (!swung) return; // Only reset if the first swing is complete
    setResetGame(true); // Set game reset state
  };

  return (
    <canvas
      ref={canvasRef}
      width={1200}
      height={600}
      style={{ border: '1px solid black' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onClick={handleGameClick} // Handle game reset click

    />
  );
};

export default Canvas;
