import './App.css';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom'
import Portfolio from './pages/Portfolio';
import { AnimatePresence } from 'framer-motion';
import About from './pages/About';
import Contact from './components/Contact';
// app.js
import Canvas from './components/displayComponents/game/Canvas';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import GradientBackground from './components/GradientBackground';


function App() {
  const location=useLocation();
  return (
    <div className="App">
      {/* <Navbar /> */}
      <ToastContainer />
      <GradientBackground/>

          <Routes location={location} key={location.pathname}>

            <Route index element={<Home />} />
            <Route path="/about" element={<Canvas />} />
            <Route path="/portfolio" element={<Portfolio />} />

          </Routes>
          <Contact/>
      
    </div>
  );
}

export default App;
