import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  max-width: 800px;
  padding: 1rem;
`;

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    border: none;
    border-bottom: 1px solid #e0e0e0;
    background-color: transparent;
  }

  .accordion-button {
    background-color: transparent;
    color: #333;
    font-weight: 600;
    padding: 1rem 0;

    &:not(.collapsed) {
      color: #007bff;
      border-bottom: 2px solid #FFFFFF;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }

  .accordion-body {
    color: #555;
  }
`;

const Title = styled.h2`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
`;

const TechStack = () => {
  return (
    <StyledContainer className="">
      <Title><b>Tech Stack</b></Title>
      <StyledAccordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Python</Accordion.Header>
          <Accordion.Body>
            I use <b>Python</b> primarily for website backends, as most of the projects on this site were built with Django. I'm familiar with <b>RESTful APIs</b> and have good experience using the <b>Django REST framework</b>. I also have experience with third-party libraries, including <b>Pandas, OpenAI, Google OAuth, and Stripe </b>among others.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>JavaScript</Accordion.Header>
          <Accordion.Body>
            All the projects on this site were built using <b>React</b> as a frontend framework. I prefer React for frontend development as it's easy to use and has many cool third-party libraries that make creating dynamic user experiences very simple. I've used <b>Framer Motion</b> and <b>Animate.css</b> quite a bit, mostly for creating transitions and page effects. For example, the gradient on this webpage was built with Framer Motion, and the transitions between sections are done with Animate.css.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Other Technologies</Accordion.Header>
          <Accordion.Body>
            This site and all my other projects are hosted on an <b>AWS Lightsail</b> instance. I used <b>NGINX</b> to create a set of virtual hosts under the domain darien-c.com. The Django backends were set up with <b>Gunicorn</b> workers and a couple of <b>cron</b> jobs. I also have some experience building <b>Express</b> backends.
          </Accordion.Body>
        </Accordion.Item>
      </StyledAccordion>
    </StyledContainer>
  );
};

export default TechStack;
