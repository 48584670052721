import React,{useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import stock from './stock.mp4';
import Button from 'react-bootstrap/Button';

import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom'
import './VideoCaro.css';
import financeVideo from './Projectvideos/finance.mp4'
import heaterVideo from './Projectvideos/Heater_Final.mp4'
import libtaxVideo from './Projectvideos/lt1.mp4'
import meandmeVideo from './Projectvideos/meandmefixed.mp4'
import rouletteVideo from './Projectvideos/Roulette.mp4'
import TIMLVideo from './Projectvideos/TIMLFinal.mp4'
const VideoCaro = () => {
  const [index, setIndex] = useState(0)
  const [cardTitle, setCardTitle] = useState('')
  const [cardDescription, setCardDescription] = useState('')
  const [isMobile, setIsMobile] = useState(false);
  const [cardLink, setCardLink] = useState('')
  const titles = {
    0: 'thisismylife',
    1: 'HEATER',
    2:'MEANDME',
    3:'FFS Finance',
    4:'Recipe Roulette',
    5: 'Liberty Tax Ajax'
    // Add more mappings as needed
  };
  const links = {
    0: 'https://timl.darien-c.com',
    1:'https://heater.darien-c.com',
    2:'https://meandme.darien-c.com',
    3:'https://finance.darien-c.com',
    4:'https://reciperoulette.darien-c.com',
    5:'https://liberty-tax-ajax.darien-c.com'
  }
  const descriptions = {
    0: "thisismylife is a mental health web app where users can share their experiences and support each other. It includes a journaling feature and AI chatbots for additional support. Building this taught me a lot about frontend design, especially using framer-motion with animate.css for smooth transitions. On the backend, I learned to efficiently integrate third-party APIs within existing structures.",
    1: "This app is a Wordle-inspired daily trivia game. Players answer five questions, with the game ending on the first wrong answer. Getting all five right puts you on a 'HEATER'. Technically, I integrated Google OAuth with Django using Knox tokens and used particles.js for an interactive background. It was a good learning experience in authentication and frontend effects.",

2: "MEANDME is a simple horoscope and numerology app. While basic in function, it allowed me to focus on visual design. I learned about creating gradient backgrounds and the impact of font choice on overall appearance. It also taught me about optimizing for different screen sizes, which was quite useful.",

3: "FFS Finance is a mock e-commerce site selling illustrations of stock chart scams. Building this site gave me hands-on experience integrating Django with Stripe for payments. It was a practical way to learn about e-commerce backend functionality.",

4: "Recipe Roulette is a straightforward app that gives users random recipes via a spin wheel. I built it to familiarize myself with Express after working with Django. It includes a comment feature for user interaction. I appreciated Express for its lightweight and flexible nature.",

5: "Liberty Tax Ajax is a basic landing page template I created for my accounting job. It includes Google Maps integration and a calendar for appointment booking. While simple, it serves its purpose effectively and gave me practice in creating functional business websites."
};

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(()=>{
    console.log(index)
    setCardTitle(titles[index])
    setCardDescription(descriptions[index])
    setCardLink(links[index])
  },[index])
  useEffect(() => {
    // Function to check screen width
    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsMobile(screenWidth <= 1000);
    };

    // Check on component mount
    checkScreenWidth();

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Clean up function to remove event listener
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  return (
    <div>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item >
          <div className="videoContainer">
            {/* Corrected video source syntax */}
            

            <video autoPlay="autoplay" playsInLine="playsInline" loop="true" muted="true"  style={{
    width: '100%',          // Set video width to 100% of its container
    height: '100%',         // Set video height to 100% of its container
    objectFit: 'contain'
  }}>
              {/* Reference the video file directly from the public folder */}
              <source src={TIMLVideo} type="video/mp4" />
            </video>
            
           
            <Carousel.Caption className="middle-caption">
            <Link to="https://timl.darien-c.com/" target="_blank">
            {!isMobile && ( 
            <div className="CCaption">
            
                <h3 style={{color:"white"}}>thisismylife</h3>
               
                  <div>
                            

                
        


              </div>
              </div>
              )}

              </Link>
            </Carousel.Caption>
            
          </div>
          
        </Carousel.Item >
        <Carousel.Item activeindex={index} onSelect={handleSelect}>
  <div className="videoContainer">
    
      <video autoPlay="autoplay" playsInLine="playsInline" loop="true" muted="true"  style={{
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }}>
        <source src={heaterVideo} type="video/mp4" />
      </video>
    

    <Carousel.Caption className="middle-caption">
    <Link to="https://heater.darien-c.com/" target="_blank">
    {!isMobile && (
      <div className="CCaption">
        <h3 style={{ color: "black" }}>HEATER</h3>
       
          <div>

                </div>
                

      </div>)}
      </Link>
    </Carousel.Caption>
  </div>
</Carousel.Item>

        <Carousel.Item >

          <div className="videoContainer">
            {/* Corrected video source syntax */}
            
            <video autoPlay="autoplay" playsInLine="playsInline" loop="true" muted="true" style={{
    width: '100%',          // Set video width to 100% of its container
    height: '100%',         // Set video height to 100% of its container
    objectFit: 'contain'    // Fit the video within the container, preserving its aspect ratio
  }}>
              {/* Reference the video file directly from the public folder */}
              <source src={meandmeVideo} type="video/mp4" />
            </video>
            <Carousel.Caption className="middle-caption">
            <Link to="https://meandme.darien-c.com/" target="_blank">
            {!isMobile && (
              <div className="CCaption">
                <h3 style={{color:"black"}}>MEANDME</h3>
                
                  <div>

                </div>
             

              </div>)}
              </Link>

            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item >
       

          <div className="videoContainer">
            {/* Corrected video source syntax */}

            <video autoPlay="autoplay" playsInLine="playsInline" loop="true" muted="true"  style={{
    width: '100%',          // Set video width to 100% of its container
    height: '100%',         // Set video height to 100% of its container
    objectFit: 'contain'    // Fit the video within the container, preserving its aspect ratio
  }}>
              {/* Reference the video file directly from the public folder */}
              <source src={financeVideo} type="video/mp4" />
            </video>
            <Carousel.Caption className="middle-caption">
            <Link to="https://finance.darien-c.com/" target="_blank">
            {!isMobile && (
              <div className="CCaption" >
                <h3 style={{color:"black"}}>FFS Finance</h3>
                
                  <div>

                </div>

              </div>)}
              </Link>

            </Carousel.Caption>
          </div>
          <a/>
        </Carousel.Item>
        <Carousel.Item >

<div className="videoContainer">
  {/* Corrected video source syntax */}
  <video autoPlay="autoplay" playsInLine="playsInline" loop="true" muted="true" style={{
width: '100%',          // Set video width to 100% of its container
height: '100%',         // Set video height to 100% of its container
objectFit: 'contain'    // Fit the video within the container, preserving its aspect ratio
}}>
    {/* Reference the video file directly from the public folder */}
    <source src={rouletteVideo} type="video/mp4" />
  </video>
  <Carousel.Caption className="middle-caption">
  <Link to="https://reciperoulette.darien-c.com/" target="_blank">
  
  {!isMobile && (
    <div className="CCaption" >
      <h3 style={{color:"black"}}>Recipe Roulette</h3>


    </div>)}
    
    </Link>

  </Carousel.Caption>
</div>
</Carousel.Item>
        <Carousel.Item >
          
          <div className="videoContainer">
            {/* Corrected video source syntax */}

            <video autoPlay="autoplay" playsInLine="playsInline" loop="true" muted="true" style={{
    width: '100%',          // Set video width to 100% of its container
    height: '100%',         // Set video height to 100% of its container
    objectFit: 'contain'    // Fit the video within the container, preserving its aspect ratio
  }}>
              {/* Reference the video file directly from the public folder */}
              <source src={libtaxVideo} type="video/mp4" />
            </video>
            <Carousel.Caption className="middle-caption">
            <Link to="https://liberty-tax-ajax.web.app/" target="_blank">
            {!isMobile && (

              <div className="CCaption" >
                <h3 style={{color:"black"}}>Liberty Tax Ajax</h3>
            

              </div>)}
              </Link>

            </Carousel.Caption>
          </div>
        
        </Carousel.Item>
      </Carousel>
      <div>
      <Card className="custom-card mb-2">
      <Card.Header className="custom-card-header">{cardTitle}</Card.Header>
      <Card.Body>
        <Card.Text>
          {cardDescription}
          <br />
          <br />
          <Link to={cardLink}>
            <Button variant="outline-dark" className="custom-button">
              Visit Site
            </Button>
          </Link>
        </Card.Text>
      </Card.Body>
    </Card>

      </div>
    </div>
  );
};

export default VideoCaro;
